.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.navbarnew-brand-logo{
  width: 3rem;
  height: 2rem;
}

.navbar-main h3{
  
  font-family: 'Texturina';
}
.navbar-main{
  background-color: #060c22;
}
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  background: #fff;
  color: #2f3138;
  font-family: "Open Sans", sans-serif;
}

a {
  color: #f82249;
  text-decoration: none;
  transition: 0.5s;
}

a:hover, a:active, a:focus {
  color: #f8234a;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  color: #0e1b4d;
}

img-fluid {
height: 50px;
width: 150px;
border-radius: 50px;
}

footer-style {
  background-color: #222;
  color: #fff;
  padding:   20px;
  display: flex;
  justify-content: space-between;
}
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar > ul > li {
  white-space: nowrap;
  padding: 10px 0 10px 12px;
}
.navbar a, .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(202, 206, 221, 0.8);
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
  padding: 6px 4px;
}
.navbar a i, .navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar > ul > li > a:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #f82249;
  visibility: hidden;
  transition: all 0.3s ease-in-out 0s;
}
.navbar a:hover:before, .navbar li:hover > a:before, .navbar .active:before {
  visibility: visible;
  width: 100%;
}
.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
  color: #fff;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 12px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}
.navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #060c22;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}
.navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
  color: #f82249;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}


/*--------------------------------------------------------------
# Speakers Section
--------------------------------------------------------------*/
#speakers {
  padding: 60px 0 30px 0;
}
#speakers .speaker {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
#speakers .speaker .details {
  background: rgba(6, 12, 34, 0.76);
  position: absolute;
  left: 0;
  bottom: -40px;
  right: 0;
  text-align: center;
  padding-top: 10px;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
#speakers .speaker .details h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
}
#speakers .speaker .details p {
  color: #fff;
  font-size: 15px;
  margin-bottom: 10px;
  font-style: italic;
}
#speakers .speaker .details .social {
  height: 40px;
}
#speakers .speaker .details .social i {
  line-height: 0;
  margin: 0 2px;
}
#speakers .speaker .details a {
  color: #fff;
}
#speakers .speaker .details a:hover {
  color: #f82249;
}
#speakers .speaker:hover .details {
  bottom: 0;
}

#speakers-details {
  padding: 60px 0;
}
#speakers-details .details h2 {
  color: #112363;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}
#speakers-details .details .social {
  margin-bottom: 15px;
}
#speakers-details .details .social a {
  background: #e9edfb;
  color: #112363;
  line-height: 1;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
#speakers-details .details .social a:hover {
  background: #f82249;
  color: #fff;
}
#speakers-details .details .social a i {
  font-size: 16px;
  line-height: 0;
}
#speakers-details .details p {
  color: #112363;
  font-size: 15px;
  margin-bottom: 10px;
}

.nav-tabs .nav-link {

  background-color: black;
  color: #060c22 !important;
  border: 1px solid grey !important;
  
}

.nav-tabs .nav-link.active {

  color: #ffffff !important;
  background-color: #060c22 !important;
}

@media only screen and (max-width: 1100px) {
  
 
  .navbarnew-brand-logo{
    width: 2rem;
    height: 2rem;
  }
  .navbar-brand h1{
    font-size: 14px;
  }
}